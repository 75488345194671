.typewriter {
    position: absolute;
    top: 40vh;
    left: 0;
    right: 0;
    z-index: 9;
    text-align: center;
    font-size: 10vw;
    font-family: "Bodman-Regular_2";
}

#root {
    cursor: url(img/x.png) 16 16, auto !important;
    color: black !important;
    font-family: "PPRadioGrotesk-Regular";
}

.carousel-control-prev-icon, .carousel-control-next-icon {
    opacity: 0%;
}

.carousel-indicators {
    display: none !important;
}

.main {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

a {
    cursor: url(img/x.png) 16 16, auto !important;
    color: black !important;
    text-decoration: none !important;
}

.row {
    margin: auto !important;
}

@font-face {
    font-family: "PPRadioGrotesk-Regular";
    src: local("PPRadioGrotesk-Regular"), url(./fonts/PPRadioGrotesk-Regular.otf) format("opentype");
}

@font-face {
    font-family: "Bodman-Regular_2";
    src: local("Bodman-Regular_2"), url(./fonts/Bodman-Regular_2.otf) format("opentype");
}

.mt-custom {
    margin-top: 8vw !important;
}

.text {
    font-size: 4.7vw !important;
}

.nav-text {
    line-height: 0.7em;
}

.nav-col {
    margin-left: -3rem;
}

.carousel-control-prev {
    width: 20% !important;
}

.carousel-control-next {
    width: 70% !important;
}